.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  left: -25px;
}

.ism-datepicker {
  font-size: inherit;
}

.ism-datepicker .react-datepicker__day-name {
  width: 2.2rem;
  line-height: 2.2rem;
  margin: 0.5rem;
}

.ism-datepicker .react-datepicker__day {
  width: 2.2rem;
  line-height: 2.2rem;
  margin: 0.5rem;
}

.ism-datepicker .react-datepicker__day--keyboard-selected:hover {
  color: white;
}

.ism-datepicker .react-datepicker__current-month {
  font-size: inherit;
}

.ism-datepicker .react-datepicker-time__header {
  font-size: inherit;
}
